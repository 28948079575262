.partnership_container {
    background-image: url('../../../public/assets/section-6-7--bg.png');
    z-index: 1;
    position: relative;
    margin-top: -330px;
    padding-top: 250px;
    padding-bottom: 200px;
}

.partnership_title {
    text-align: center;
    color: #990000;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    width: auto !important;
}

.partnership_icon {
    text-align: center;
    color: white;
}

.bottom_row {
    margin-top: 50px !important;
}

.partnership_icon img {
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    transition: all 0.5s;
}


@media only screen and (min-width: 900px)
{
    .partnership_icon img:hover {
        transform: translateY(-20px);
        transition: all 0.5s;
    }
}

.partnership_icon_title {
    font-size: 20px;
    font-weight: 400;
    margin: 20px 0;
}

.partnership_icon_content {
    font-size: 20px;
    font-weight: 200;
    margin: 20px 0;
}

.partnership_content_container {
    padding: 0 100px;
    display: flex;
}

.partnership_container .row {
    margin: 100px 0;
    justify-content: center;
}

.up_arrow {
    position: absolute;
    right: 30px;
    bottom: 30px;
    background-color: #990000;
    padding: 10px;
    border-radius: 999px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999
}

@media only screen and (max-width: 2000px) {
    .partnership_content_container {
        padding: 0 100px;
        display: flex;
    }    
}

@media only screen and (max-width: 1750px) {
    .partnership_content_container {
        padding: 0 50px;
        display: flex;
    }    
}

@media only screen and (max-width: 1350px) {
    .partnership_content_container .col-4 {
        width: 33%;
        padding: 20px;
    }

    .partnership_content_container {
        padding: 0 50px;
        display: flex;
    }    
}

@media only screen and (max-width: 1050px) {
    .partnership_content_container .col-4 {
        width: 33%;
        padding: 20px;
    }

    .partnership_icon img {
        width: 220px;
    }
}

@media only screen and (max-width: 750px) {
    
    .partnership_content_container .col-4 {
        width: 100%;
        padding: 20px;
    }

    .partnership_icon img {
        width: 220px;
    }

    .bottom_row {
        margin-top: 0px !important;
    }
}

@media only screen and (max-width: 620px) {
    .partnership_content_container {
        display: block;
    }

    .partnership_icon img {
        width: 220px;
    }

    .partnership_container .row {
        margin: 150px 0 -110px 0;
        justify-content: center;
        /* background-color: #990000; */
    }

    .bottom_row {
        margin-top: 0px !important;
    }
    
}

