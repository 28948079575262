.video_containter{
    /* margin-top: -170px; */
    margin-bottom:50px;
    flex-grow: 1;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    
}

@media only screen and (min-width: 2000px) {
    .video_containter .video_outer_wrapper{
        
        width:1297px ;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        

    }
    .video_wrapper video{
        max-width: 634px;
        min-width: 600px;   
    }

   
  }
@media only screen and (max-width: 2000px) {
    .video_containter .video_outer_wrapper{
        
        width:1297px ;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      

    }
    .video_wrapper video{
        max-width: 634px;
        min-width: 600px;   
    }
  }

  @media only screen and (max-width: 1450px) {
    .video_containter .video_outer_wrapper{
        
        width:1297px ;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    

    }
    .video_wrapper video{
        max-width: 634px;
        min-width: 600px;   
    }
  }
  @media only screen and (max-width: 1315px) {
    .video_containter .video_outer_wrapper{
        
        width:1187px ;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    

    }
    .video_wrapper video{
        max-width: 578px;
        min-width: 578px;   
    }
  }

  /* @media only screen and (max-width: 1283px) {
    .video_containter .video_outer_wrapper{
        
        width:1240px ;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
       
    }
   .video_wrapper video{
        width: 605px;
        min-width: 600px;   
    }
  } */
  @media only screen and (max-width: 1200px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
       
    }
   .video_wrapper video{
        width: 555px;
        min-width: 400px;   
    }
  }
  @media only screen and (max-width: 1000px) {
   
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
       
    }
   .video_wrapper video{
        width: 432px;
        min-width: 400px;   
    }
  }
  @media only screen and (max-width: 820px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
       
    }
   .video_wrapper video{
        width: 389px;
        min-width: 300px;   
    }
  }

  @media only screen and (max-width: 800px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
       
    }
   .video_wrapper video{
        width: 370px;
        min-width: 300px;   
    }
  }
  @media only screen and (max-width: 720px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
       
    }
   .video_wrapper video{
        width: 650px;
        min-width: 650px;   
    }
  }
  @media only screen and (max-width: 672px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
       
    }
   .video_wrapper video{
    max-width: 504px;
    min-width: 505px;  
    }
  }
  @media only screen and (max-width: 600px) {
    .video_containter .video_outer_wrapper{
        
        width:98% ;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
       
    }
   .video_wrapper video{
    max-width: 407px;
    margin-top: -35px;
    min-width: 200px; 
    
    }

    
  }

 