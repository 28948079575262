@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
.header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
  z-index: 100;

}

.logo {
  text-align: center;
  width: 15%;
  line-height: 120px;
  font-weight: bold;
  font-size: 1vw;
}

.logo img {
  width: 10vw;
}

.menu {
  padding: 50px 120px;
  display: flex;
  justify-content: space-between;
  width: 1050px;
  float: right;
}

.menu_item {
  padding: 0 14px;
  color: #FBFBFD;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-duration: 1s;
}

.menu_item:nth-child(1) {
    animation-delay: 0s;
}

.menu_item:nth-child(2) {
    animation-delay: 0.1s;
}

.menu_item:nth-child(3) {
    animation-delay: 0.2s;
}

.menu_item:nth-child(4) {
    animation-delay: 0.3s;
}

.menu_item:nth-child(5) {
    animation-delay: 0.4s;
}

.menu_item:nth-child(6) {
    animation-delay: 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.menu_item:hover {
    color: #7ec2be;
    transition: all 0.5s;
}

@media only screen and (max-width: 1350px) {
    .menu {
      padding: 40px 120px;
    }
    .logo {
        line-height: 100px;
      }
  }

@media only screen and (max-width: 1100px) {
  .menu {
    padding: 40px 5px;
    display: flex;
    justify-content: space-between;
    width: 726px;
    float: right;
  }
  .logo {
    text-align: center;
    width: 15%;
    line-height: 100px;
    font-weight: bold;
    font-size: 1vw;
  }
}

#toggle {
  display: none;
}

.toggle-container {
  display: none;
}

.nav {
  display: none !important;
}


@media only screen and (max-width: 800px)
{

    .logo img {
        width: 100px;
        margin: 15px 20px 0 20px;
    }

    .logo {
        line-height: 0px;
    }

    .header {
        position: relative;
    }

    .header .col-10 {
        display: none;
    }

    .menu {
        display: none;
    }

    /* Toggle Functionality */
    #toggle {
        display: none;
    }

    .toggle-container {
        display: flex;
    }

    a {
        text-decoration: none !important;
    }

    .nav {
        display: flex !important;
    }

    #toggle:checked ~ .image_container {
        top: 280px;
        transition: all 0.5s;
    }

    #toggle:checked ~ .toggle-container .button-toggle {
        box-shadow: 0 0 0 800px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }
    #toggle:checked ~ .toggle-container .button-toggle:before {
        transform: translateY(-50%) rotate(45deg) scale(1);
    }
    #toggle:checked ~ .toggle-container .button-toggle:after {
        transform: translateY(-50%) rotate(-45deg) scale(1);
    }
    #toggle:checked ~ .nav {
        margin-bottom: 100px;
        pointer-events: auto;
        transform: translate(-160px, 50px);
    }
    #toggle:checked ~ .nav .nav-item {
        color: #EC7263;
        letter-spacing: 0;
        height: 40px;
        line-height: 40px;
        margin-top: 0;
        opacity: 1;
        transform: scaleY(1);
        transition: 0.5s, opacity 0.1s;
        font-size: 20px;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(1) {
        transition-delay: 0.15s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(1):before {
        transition-delay: 0.15s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(2) {
        transition-delay: 0.1s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(2):before {
        transition-delay: 0.1s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(3) {
        transition-delay: 0.05s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(3):before {
        transition-delay: 0.05s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(4) {
        transition-delay: 0s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(4):before {
        transition-delay: 0s;
    }
    #toggle:checked ~ .nav .nav-item:before {
        opacity: 0;
    }
    #toggle:checked ~ .dummy-content {
        padding-top: 30px;
    }
    #toggle:checked ~ .dummy-content:before {
        background-color: rgba(0, 0, 0, 0.3);
    }

    /* Toggle Button */
    .button-toggle {
        position: absolute;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 20px 32px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 100%;
        transition: 0.6s;
    }
    .button-toggle:hover {
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }
    .button-toggle:before, .button-toggle:after {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #EC7263;
        border-radius: 5px;
        transition: 0.5s;
    }
    .button-toggle:before {
        transform: translateY(-50%) rotate(45deg) scale(0);
    }
    .button-toggle:after {
        transform: translateY(50%) rotate(-45deg) scale(0);
    }

    /* Menu */
    .nav {
        display: inline-block;
        margin: 20px 30px 20px;
        pointer-events: none;
        transition: 0.5s;
        flex-direction: column;
        width: 20px;
    }

    .nav-item {
        position: relative;
        display: inline-block;
        float: left;
        clear: both;
        color: transparent;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -6.2px;
        height: 7px;
        line-height: 7px;
        text-transform: uppercase;
        white-space: nowrap;
        transform: scaleY(0.2);
        transition: 0.5s, opacity 1s;
        cursor: pointer;
    }
    .nav-item:nth-child(1) {
        transition-delay: 0s;
    }
    .nav-item:nth-child(1):before {
        transition-delay: 0s;
    }
    .nav-item:nth-child(2) {
        transition-delay: 0.05s;
    }
    .nav-item:nth-child(2):before {
        transition-delay: 0.05s;
    }
    .nav-item:nth-child(3) {
        transition-delay: 0.1s;
    }
    .nav-item:nth-child(3):before {
        transition-delay: 0.1s;
    }
    .nav-item:nth-child(4) {
        transition-delay: 0.15s;
    }
    .nav-item:nth-child(4):before {
        transition-delay: 0.15s;
    }
    .nav-item:nth-child(1) {
        letter-spacing: -8px;
    }
    .nav-item:nth-child(2) {
        letter-spacing: -7px;
    }
    .nav-item:nth-child(n+4) {
        letter-spacing: -8px;
        margin-top: -7px;
        opacity: 0;
    }
    .nav-item:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #EC7263;
        transform: translateY(-50%) scaleY(5);
        transition: 0.5s;
    }
}

.ReactModal__Overlay{
    z-index: 1000000
}