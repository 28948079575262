.notice_detail_container{
    display: flex;
    /* background-color: antiquewhite; */
    justify-content: center;
    flex-direction: column;
}
.notice_detail_container_wrapper{
    display: flex;
    /* background-color: yellow; */
    flex-direction: column;

}
.notice_detail_container .notice_detail_container_wrapper .notice_details_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aquamarine; */
    flex-direction: column;
}
.notice_details_wrapper_title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color:#000;
    margin:10px 20px
}
.notice_details_wrapper_date{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color:gray;
 
}
.notice_details_body{
    margin:10px 0px
}
.notice_details_file_box{
    margin:30px 0px;
    flex-direction: row;
    display: flex;
    align-items: center;

}
.notice_details_file_btn_txt{
    padding: 10px 20px;
    border:1px solid rgb(163, 163, 163);
    border-radius: 3px;
}
.notice_details_file{
    margin-left:20px
}
.notice_details_file_box >a{
    /* color: #000; */
}