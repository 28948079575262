.enterprise_container_pc{
    display: flex;

    align-items: center;
    height: 109vh;
    background-color: #fff;
    flex-direction: column;
}
.enterprise_header_pc{
    height: 68px;
    width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0 auto
    /* position: sticky;
    top: 0; */
}
.enterprise_center_container_pc{
    width: 1000px;

    margin: 0 auto;
    height: 100vh;
    
}
.enterprise_header_left_pc{
    width: 350px;
 
    /* padding-left: 55px; */
    padding-top: 10px;

}
.enterprise_header_left_pc img{
    width: 200px;
}

.enterprise_header_right_pc{
    width: 314px;
   
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.enterprise_header_right_id_pc{
    font-weight: bold;
    padding: 4px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 3px;
    font-size: 17px;
}
.enterprise_header_right_logout_pc{
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    background-color: rgb(212, 212, 212);
    border-radius: 3px;
    font-size: 17px;
}

.enterprise_content_container_pc{
    display: flex;
    margin-top: 50px;
    
}

.enterprise_content_left_pc{
    display: flex;
    align-items: center;
    width: 190px;
    flex-direction: column;
}
.enterprise_content_parent_right_pc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #ffe1e1;
}
.enterprise_content_right_text_pc{
    display: flex;
    align-items: center;
    width: 799px;
    text-align: center;
    justify-content: center;
    /* margin-left: 10px; */
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 15px;
    border-bottom: 1px solid #ffe1e1;

}

.enterprise_content_right_pc{
    margin-left: 10px;
    display: flex;
    /* align-items: center; */
}

.enterprise_content_right_left_pc{
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
   

}
.enterprise_content_right_left_button_pc{
    padding: 10px 20px; /* Add padding to style buttons */
  background-color: #007bff; /* Example background color */
  color: #fff; /* Example text color */
  border: none;
  cursor: pointer;
  width:  120px;
  margin-bottom: 10px;
  border-radius: 3px;
  font-size: 13px;


}

.enterprise_content_right_right_pc{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    width: 79%;
}

.enterprise_content_right_right_calendar_pc{

    margin-bottom: 30px;
    width: 140px;
    background-color: #ffe1e1;
    padding: 10px;
    border: 1px solid #ffe1e1;
    border-radius:5px;
    font-size: 17px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.calendar_icon{
    display: flex!important;
  justify-content: center!important;
  flex-direction: row!important;
}

.enterprises_service_table_responsive_pc{
    overflow-x: auto;
   
    position: relative;
    margin-bottom: 50px;
    height: 81vh;
}

.enterprises_service_table_pc {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border: 1px solid #ddd;
  }
.nonsticky_table_header{
    top: 0; /* Stick the header to the top of the container */
    background-color: #fff;
    z-index: 1;
}

.sticky_table_header{
    position: sticky;
    top: 0; /* Stick the header to the top of the container */
    background-color: #fff;
    z-index: 1;
}
  
.enterprise_content_right_right_table_pc{
    display: block;
}


.enterprise_content_left_button_pc{
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 181px;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 13px;
}



.enterprises_service_table_responsive_pc .enterprises_service_table_pc .table_head {

    display: contents;
  }
  
  
  .enterprises_service_table_responsive_pc table th,
  table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  /* Add styles for even and odd rows */
  .enterprises_service_table_responsive_pc table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Hover effect on rows */
  .enterprises_service_table_responsive_pc table tbody tr:hover {
    background-color: #e0e0e0;
  }
  












/* For tablet  */

.enterprise_container_tab{
    display: flex;

    align-items: center;
    height: 100vh;
    flex-direction: column;
}
.enterprise_header_tab{
    height: 68px;
    width: 808px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0 auto
    /* position: sticky;
    top: 0; */
}
.enterprise_center_container_tab{
    width: 808px;
    margin: 0 auto;
    height: 100vh;
    
}
.enterprise_header_left_tab{
    width: 350px;
    /* padding-left: 55px; */
    padding-top: 10px;

}
.enterprise_header_left_tab img{
    width: 200px;

}

.enterprise_header_right_tab{
    width: 237px;
    /* padding-right: 55px; */
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.enterprise_header_right_id_tab{
    font-weight: bold;
    padding: 4px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 3px;
    font-size: 17px;
}
.enterprise_header_right_logout_tab{
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    background-color: rgb(212, 212, 212);
    border-radius: 3px;
    font-size: 17px;
}

.enterprise_content_container_tab{
    display: flex;
    margin-top: 50px;

}

.enterprise_content_left_tab{
    display: flex;
    align-items: center;
    width: 162px;
    flex-direction: column;
}
.enterprise_content_parent_right_tab{
    display: flex;
    flex-direction: column;
    width: 638px;
    justify-content: center;
  
    border: 1px solid #ffe1e1;
}
.enterprise_content_right_text_tab{
    display: flex;
    align-items: center;
    width: 580px;
    text-align: center;
    justify-content: center;
    /* margin-left: 10px; */
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 15px;
    border-bottom: 1px solid #ffe1e1;
}

.enterprise_content_right_tab{
    margin-left: 10px;
    display: flex;
   
}

.enterprise_content_right_left_tab{
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
   

}
.enterprise_content_right_left_button_tab{
    padding: 10px 20px; /* Add padding to style buttons */
  background-color: #007bff; /* Example background color */
  color: #fff; /* Example text color */
  border: none;
  cursor: pointer;
  width:  120px;
  margin-bottom: 10px;
  border-radius: 3px;
  font-size: 13px;


}

.enterprise_content_right_right_tab{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    width: 79%;
}

.enterprise_content_right_right_calendar_tab{

    margin-bottom: 30px;
    width: 140px;
    background-color: #ffe1e1;
    padding: 10px;
    border: 1px solid #ffe1e1;
    border-radius:5px;
    font-size: 17px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.calendar_icon{
    display: flex!important;
  justify-content: center!important;
  flex-direction: row!important;
}

.enterprises_service_table_responsive_tab{
    overflow-x: auto;

   
    position: relative;
    margin-bottom: 50px;
    height: 70vh
}

.enterprises_service_table_tab {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border: 1px solid #ddd;
  }
.nonsticky_table_header{
    top: 0; /* Stick the header to the top of the container */
    background-color: #fff;
    z-index: 1;
}

.sticky_table_header{
    position: sticky;
    top: 0; /* Stick the header to the top of the container */
    background-color: #fff;
    z-index: 1;
}
  
.enterprise_content_right_right_table_tab{
    display: block;
}


.enterprise_content_left_button_tab{
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 150px;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 13px;
}




.enterprises_service_table_responsive_tab .enterprises_service_table_tab .table_head {

    display: contents;
  }
  
  
  .enterprises_service_table_responsive_tab table th,
  table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  /* Add styles for even and odd rows */
  .enterprises_service_table_responsive_tab table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Hover effect on rows */
  .enterprises_service_table_responsive_tab table tbody tr:hover {
    background-color: #e0e0e0;
  }
  


/* Css For Mobile */

.enterprise_container_mob{
    display: flex;

    align-items: center;
    height: 149vh;
    flex-direction: column;
}
.enterprise_header_mob{
    height: 68px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: sticky;
    top: 0; */
}
.enterprise_center_container_mob{
    width: 100%;
    height: 147vh;
    
}
.enterprise_header_left_mob{
    width: 143px;
    padding-left: 20px;
    padding-top: 10px;

}
.enterprise_header_left_mob img{
    width: 118px;
}

.enterprise_header_right_mob{
    width: 222px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.enterprise_header_right_id_mob{
    font-weight: bold;
    padding: 4px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 3px;
    font-size: 12px;
}
.enterprise_header_right_logout_mob{
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    background-color: rgb(212, 212, 212);
    border-radius: 3px;
    font-size: 12px;
}

.enterprise_content_container_mob{
    display: flex;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
    margin-bottom: 17px;

  
}

.enterprise_content_left_mob{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.enterprise_content_parent_right_mob{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #ffe1e1;
    width: -webkit-fill-available;
}
.enterprise_content_right_text_mob{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 15px;
    border-bottom: 1px solid #ffe1e1;
}

.enterprise_content_right_mob{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.enterprise_content_right_left_mob{
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: center;
   
   

}
.enterprise_content_right_left_button_mob{
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 120px;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 13px;
    margin-left: 12px;


}

.enterprise_content_right_right_mob{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 26px;
}

.enterprise_content_right_right_calendar_mob{

    margin-bottom: 30px;
    width: 140px;
    background-color: #ffe1e1;
    padding: 10px;
    border: 1px solid #ffe1e1;
    border-radius:5px;
    font-size: 17px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.calendar_icon{
    display: flex!important;
  justify-content: center!important;
  flex-direction: row!important;
  font-size: 15px;
  align-items: center;
}

.enterprises_service_table_responsive_mob{
    overflow-x: auto;

   
    position: relative;
    margin-bottom: 50px;
    height: 70vh
}

.enterprises_service_table_mob {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border: 1px solid #ddd;
  }
.nonsticky_table_header{
    top: 0; /* Stick the header to the top of the container */
    background-color: #fff;
    z-index: 1;
}

.sticky_table_header{
    position: sticky;
    top: 0; /* Stick the header to the top of the container */
    background-color: #fff;
    z-index: 1;
}
  
.enterprise_content_right_right_table_mob{
    display: block;
    height: 60vh;
}


.enterprise_content_left_button_mob{
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 150px;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 13px;
}




.enterprises_service_table_responsive_mob .enterprises_service_table_mob .table_head {

    display: contents;
  }
  
  
  .enterprises_service_table_responsive_mob table th,
  table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  /* Add styles for even and odd rows */
  .enterprises_service_table_responsive_mob table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Hover effect on rows */
  .enterprises_service_table_responsive_mob table tbody tr:hover {
    background-color: #e0e0e0;
  }
  

/* Common css */
.enterprise_pc_version_text{
    background-color: rgb(212, 212, 212);
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 12px;
    /* margin:53px 130px; */
  }



  .enterprise_pc_version_text_pc{
    background-color: rgb(212, 212, 212);
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 17px;
    /* margin:53px 130px; */
  }
























