.recruitment_container {
    background-color: transparent;
    z-index: 2;
    position: relative;
    height: 810px;
    /* margin-top: -450px; */
    margin-bottom: 100px;
}

.recruitment_background {
    background-image: url('../../../public/assets/section-5--img.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 80%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: -2;
    border-radius: 20px;
}

.recruitment_title {
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    width: auto !important;
    margin: 100px 0 0 0 !important;
}

.recruitment_content {
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 300;
    position: relative;
    width: auto !important;
    margin: 50px 0 !important;
}

.recruitment_info {
    padding: 20px !important;
    opacity: 0;
    transform: translateY(20px);
}

.recruitment_show1 {
    opacity: 1;
    transform: translateY(0px);
    transition: all 1s;
}

.recruitment_show2 {
    opacity: 1;
    transform: translateY(0px);
    transition: all 1s;
    transition-delay: 0.1s;
}

.recruitment_show3 {
    opacity: 1;
    transform: translateY(0px);
    transition: all 1s;
    transition-delay: 0.1s;
}

.recruitment_info_container {
    background-color: rgba(255, 255, 255, .15);  
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 10px;
    height: 390px;
}

.recruitment_content_container {
    padding: 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recruitment_info_title {
    padding: 50px 40px 30px 40px;
    color: #7ec2be;
    font-size: 18px;
}

.divider {
    border-top: solid 2px #93a0a8;
    margin: 0 30px;
}

.recruitment_info_content_title {
    font-size: 18px;
    color: white;
    padding: 30px 40px 0 40px;
}

.recruitment_info_content_body {
    font-size: 18px;
    color: white;
    font-weight: 200;
    padding: 10px 40px 0 60px;
    position: relative;
}

.recruitment_info_content_body:before{
    content: '.';
    position: absolute;
    left: 50px;
    top: 4px;
}

@media only screen and (max-width: 2200px) {
    .recruitment_info_title {
        font-size: 18px;
    }
    .recruitment_info_content_body {
        font-size: 18px;
    }
    .recruitment_info_content_title {
        font-size: 18px;
    }
}

@media only screen and (max-width: 2030px) {
    .recruitment_content_container {
        padding: 0 50px;
    }
}

@media only screen and (max-width: 1900px) {
    .recruitment_info_content_title {
        font-size: 17px;
        color: white;
        padding: 20px 30px 0 30px;
    }
    .recruitment_info_title {
        padding: 40px 30px 20px 30px;
        color: #7ec2be;
        font-size: 17px;
    }
    .recruitment_info_content_body {
        font-size: 17px;
        padding: 10px 40px 0 60px;
    }
    .recruitment_info_container {
        height: 350px;
    }
}

@media only screen and (max-width: 1735px) {
    .recruitment_info_content_title {
        font-size: 17px;
        color: white;
        padding: 10px 20px 0 20px;
    }
    .recruitment_info_title {
        padding: 30px 20px 10px 20px;
        color: #7ec2be;
        font-size: 17px;
    }
    .recruitment_info_content_body {
        font-size: 17px;
        padding: 10px 40px 0 30px;
    }
    .recruitment_info_container {
        height: 350px;
    }
    .recruitment_info_content_body:before{
        content: '.';
        position: absolute;
        left: 20px;
        top: 4px;
    }
    .divider {
        margin: 0 20px;
    }
    .recruitment_content_container {
        padding: 0 50px;
    }
}

@media only screen and (max-width: 1635px) {
    .recruitment_info_content_title {
        font-size: 15px;
        color: white;
        padding: 10px 20px 0 20px;
    }
    .recruitment_info_title {
        padding: 30px 20px 10px 20px;
        color: #7ec2be;
        font-size: 15px;
    }
    .recruitment_info_content_body {
        font-size: 15px;
        padding: 10px 40px 0 30px;
    }
    .recruitment_info_container {
        height: 350px;
    }
    .recruitment_info_content_body:before{
        content: '.';
        position: absolute;
        left: 20px;
        top: 4px;
    }
    .divider {
        margin: 0 20px;
    }
    .recruitment_content_container {
        padding: 0 50px;
    }
    .recruitment_info_container {
        height: 300px;
    }
}

@media only screen and (max-width: 1510px) {
    .recruitment_content_container {
        padding: 0 10px;
    }

    .recruitment_info {
        padding: 10px !important;
    }
}

@media only screen and (max-width: 1310px) {
    .recruitment_background {
        background-position: center center;
        width: 100%;
        border-radius: 0;
    }
}

@media only screen and (max-width: 1050px) {
    .recruitment_title {
        margin: 50px 0 50px 0 !important;
    }

    .recruitment_content {
        margin: 10px 0 30px 0 !important;
        font-size: 17px;
    }

    .recruitment_content_container {
        display: block;
    }

    .recruitment_info {
        width: 100% !important;
    }

    .recruitment_info_container {
        height: 230px;
    }

    .recruitment_container {
        height: 1220px;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 900px) {
    .recruitment_container {
        margin-top: -10px;
    }
}

@media only screen and (max-width: 400px) {
    .recruitment_content {
        font-size: 14px;
    }
}