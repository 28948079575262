.spop_container {
    width: 100%;
    display: flex;
}

.spop_background {
    background-image: url('../../../../public/assets/spop/spop_top_background.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    position: relative;
}

.service_intro {
    background-color: #990000;
    width: 20vw;
    font-size: 1.3vw;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 40px;
    font-weight: bold;
    padding: 0.2vw 0;
    margin-top: 3vw;
}

.spop_top_title_img {
    position: absolute;
    top: 12vw;
    left: 18vw;
    width: 25vw;
}

.spop_top_title_text {
    width: 30vw;
    position: absolute;
    left: 18vw;
    top: 16vw;
    font-size: 1vw;
}

.spop_intro_title {
    margin-left: auto;
    margin-right: auto;
    width: 10vw;
    margin-top: 4vw;
}

.portfolio_container {
    background-color: #f9f9f9;
    margin: 0 15vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    border-radius: 20px;
    margin-top: -120px;
}

.intro_inner_container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
    justify-content: space-around;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.spop_center_text {
    text-align: center;
    margin-top: 3vw;
    font-size: 1.3vw;
    padding: 0 7vw;
}

.spop_service_body {
    margin-bottom: 200px;
}

.spop_portfolio_center_text {
    text-align: center;
    margin: 1vw 0;
    font-size: 1vw;
}

.spop_portfolio_inner_container {
    display: flex;
    /* margin-left: auto;
    margin-right: auto; */
    width: 824px;
    align-items: center;
    justify-content: flex-start;
}

.spop_portfolio_right_container {
    padding: 1vw;
    width: 824px

}

.portfolio_img {
    width: 10vw;
    height: 10vw;
}

.port_top {
    background-color: white;
    border-color: #d4d4d4;
    border-width: 4px;
    border-style: solid;
    padding: 1vw;
}

.port_bottom {
    display: flex;
    align-items: center;
}

.port_bottom_title {
    background-color: #394350;
    color: white;
    width: 8vw;
    height: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 0.7vw;
}

.port_bottom_body {
    padding: 10px;
    text-align: left;
    font-size: 0.7vw;
}

.port_top_title {
    color: #990000;
    font-weight: bold;
    font-size: 1.5vw;
    text-align: left;
}

.port_top_body {
    text-align: left;
    font-size: 1vw;
}

.spop_hashtag {
    font-size: 1.2vw;
    color: #990000
}

.spop_steps {
    width: 12vw;
    margin-left: auto;
}

.spop_checkpoint_background {
    background-image: url('../../../../public/assets/spop/spop_checkpoint_background.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    position: relative;
}

.checkpoint_title {
    font-family: 'GmarketBold';
    color: #1b54bc;
    font-size: 1.6vw;
}

.checkpoint_body {
    font-size: 1vw;
}

.checkpoint_right_container {
    display: flex;
}

.text_title {
    font-family: 'GmarketBold';
    text-align: center;
    font-size: 2.2vw;
    margin-top: 2vw;
}


.spop_checkpoint_text {
    color: white;
    font-size: 2.2vw;
    font-family: 'GmarketBold';
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
}

.spop_checkpoint_inner_container {
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
}

.spop_checkpoint_hashtag {
    background-color: white;
    color: #2a60c1;
    font-family: 'GmarketBold';
    padding: 0.5vw;
    font-size: 1vw;
    border-radius: 0.5vw;
    margin-bottom: 1.5vw;
}

.spop_register_button {
    color: #06293a;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vw;
    margin-bottom: 3vw;
    padding: 1vw 2vw;
    font-family: 'GmarketBold';
    font-size: 1.2vw;
    border-radius: 1vw;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.portfolio_outer_container {
    padding-bottom: 40px;
}

.download_icon {
    width: 35px;
    height: 35px;
    margin-left: 1vw;
}
.sol_img{
    padding: 18px 343px
}
@media only screen and (min-width: 2100px) {
    .sol_img{
        padding: 18px 783px;
      
    }
}
@media only screen and (max-width: 1300px) {
    .sol_img{
        padding: 18px 283px!important;
     
    }
    .portfolio_container {
        margin: 0 10vw;
        margin-top: -120px;
    }

    .spop_portfolio_inner_container {
        width:700px;
    }

    .portfolio_img {
        width: 15vw;
        height: 15vw;
    }

    .port_top_title {
        font-size: 2.2vw;
    }

    .port_top_body {
        font-size: 1.1vw;
    }

    .port_bottom_title {
        width: 10.6vw;
        height: 2vw;
        font-size: 1.1vw;
    }

    .port_bottom_body {
        font-size: 0.9vw;
    }

    .download_icon {
        width: 25px;
        height: 25px;
    }
}

@media only screen and (max-width: 1000px) {
    .spop_portfolio_inner_container {
        width: 60vw;
    }
    .sol_img{
        padding: 18px 224px!important;
     
    }
    .spop_top_title_img {
        top: 9vw;
        width: 64vw;
        left: 9vw;
    }

    .spop_top_title_text {
        top: 17vw;
        width: 52vw;
        font-size: 2vw;
        left: 9vw;
    }

    .service_intro {
        background-color: #990000;
        width: 34vw;
        font-size: 2vw;
        color: white;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        border-radius: 40px;
        font-weight: bold;
        padding: 0.2vw 0;
        margin-top: 3vw;
    }

    .checkpoint_inner_container {
        flex-direction: column;
        width: 80vw;
    }

    .checkpoint_text {
        text-align: center;
    }

    .checkpoint_right_container {
        margin-left: auto;
        margin-right: auto;
    }
    
    .checkpoint_right_inner_container {
        width: 37vw;
        height: 35vw;
    }

    .spop_checkpoint_sect1 {
        width: 35vw;
        height: 17vw;
    }

    .spop_checkpoint_sect2 {
        width: 35vw;
        height: 17vw;
    }

    .spop_checkpoint_sect3 {
        width: 35vw;
        height: 35vw;
    }
    
    .checkpoint_title {
        font-size: 2.5vw;
    }

    .checkpoint_body {
        font-size: 2vw;
    }

    .register_container {
        width: 60vw;
    }

    .register_title {
        font-size: 2.5vw;
    }

    .register_normal_text {
        font-size: 2vw;
    }

    .register_list_title {
        font-size: 2vw;
    }

    .register_dot_top_container {
        font-size: 2vw;
    }

    .register_dot_bottom_container {
        font-size: 2vw;
    }

    .steps_image {
        width: 2vw;
    }

    .steps_text {
        font-size: 2vw;
    }

    .text_title {
        font-size: 3vw;
    }

    .spop_center_text {
        font-size: 1.5vw;
    }

    .spop_portfolio_center_text {
        font-size: 2vw;
    }

    .spop_checkpoint_hashtag {
        font-size: 2vw;
    }

    .spop_checkpoint_inner_container {
        width: 70vw;
    }

    .spop_checkpoint_text {
        width: 70vw;
        font-size: 3vw;
    }

    .spop_register_button {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 850px) {
    .spop_portfolio_inner_container {
        width: 46vw;
    }
    .sol_img{
        padding: 18px 200px!important;
     
    }
}

@media only screen and (max-width: 780px) {
    .register_container {
        width: 100vw;
        padding: 0 3vw;
    }

    .download_icon {
        width: 20px;
        height: 20px;
    }
    .sol_img{
        padding: 18px 100px!important;
     
    }
}

@media only screen and (max-width: 700px) {
    .spop_register_mobile {
        flex-direction: column;
    }
    .sol_img{
        padding: 18px 100px!important;
     
    }

    .download_icon {
        width: 40px;
        height: 40px;
    }

    .spop_portfolio_left_container {
        display: none;
    }

    .portfolio_outer_container {
        padding-bottom: 0;
    }

    .portfolio_container {
        margin: 0;
        border-radius: 0;
    }

    .spop_portfolio_inner_container {
        width: 90vw;
    }

    .portfolio_img {
        width: 25vw;
        height: 25vw;
    }

    .spop_portfolio_center_text {
        font-size: 4vw;
        padding: 0 8vw;
    }

    .port_bottom {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2vw;
    }

    .port_top_title {
        font-size: 4.3vw;
    }

    .port_top_body {
        font-size: 2.5vw;
    }

    .port_bottom_title {
        width: 25vw!important;
        height: 4.6vw;
        font-size: 2.9vw;
    }

    .port_bottom_body {
        font-size: 4vw;
    }

    .text_title {
        font-size: 4vw;
    }

    .spop_service_body {
        margin-bottom: 60px;
    }

    .spop_center_text {
        font-size: 2.5vw;
        padding: 0 5vw;
    }

    .spop_checkpoint_text {
        width: 90vw;
        font-size: 7.2vw;
    }

    .spop_checkpoint_inner_container {
        width: 90vw;
    }

    .spop_checkpoint_hashtag {
        font-size: 4vw;
    }

    .spop_register_button {
        font-size: 5.2vw;
        margin-top: 8vw;
    }

    .spop_register_mobile {
        padding-bottom: 8vw;
    }
}

@media only screen and (max-width: 660px) {
    .spop_background {
        background-size: cover;
        height: 260px;
        background-position: center;
    }

    .spop_top_title_img {
        top: 3vw;
        width: 64vw;
        left: 9vw;
    }

    .spop_top_title_text {
        top: 10vw;
        width: 66vw;
        font-size: 2vw;
        left: 9vw;
    }

    .service_intro {
        background-color: #990000;
        width: 48vw;
        font-size: 3.3vw;
        color: white;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        border-radius: 40px;
        font-weight: bold;
        padding: 0.2vw 0;
        margin-top: 7vw;
    }

    .text_title {
        font-size: 4.2vw;
        margin-top: 4vw;
    }

    .spop_intro_title {
        width: 22vw;
    }

    .spop_center_text {
        font-size: 2vw;
    }

    .spop_hashtag {
        font-size: 2vw;
    }

    .mobile_container {
        height: 450px;
    }

    .three_container {
        width: 75vw;
        margin-top: 12vw;
    }

    .three_container_ind {
        width: 25vw;
        text-align: center;
    }

    .spop_steps {
        width: 20vw;
    }

    .three_container_text {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 660px) {
    .spop_top_title_img {
        top: 9vw;
    }

    .spop_top_title_text {
        top: 17vw;
    }
}

@media only screen and (max-width: 500px) {
    .download_icon {
        width: 30px;
        height: 30px;
    }

    .spop_portfolio_left_container {
        display: none;
    }

    .port_bottom_title {
        width: 25vw;
        height: 7.6vw;
    }

    .spop_top_title_text {
        font-size: 4vw;
    }

    .spop_background {
        height: 270px;
    }

    .intro_inner_container {
        width: 90vw;
        margin-bottom: 40px;
    }
    
    .mobile_container {
        height: 440px;
        padding-top: 30px;
    }

    .three_container {
        width: 95vw;
    }

    .three_container_ind {
        width: 32vw;
    }

    .service_intro {
        width: 42vw;
        font-size: 2.8vw;
    }

    .text_title {
        font-size: 6.2vw;
    }

    .spop_intro_title {
        width: 40vw;
    }

    .spop_center_text {
        font-size: 2.8vw;
        padding: 0 2vw;
    }

    .spop_hashtag {
        font-size: 3vw;
    }

    .spop_steps {
        width: 27vw;
    }

    .three_container_text {
        font-size: 4vw;
    }

    .three_container {
        margin-top: 7vw;
    }

    .checkpoint_text {
        font-size: 6.2vw;
    }

    .checkpoint_right_container {
        flex-direction: column;
    }

    .checkpoint_inner_container {
        justify-content: center;
    }

    .checkpoint_right_inner_container {
        width: 80vw;
        height: 75vw;
    }

    .spop_checkpoint_sect1 {
        width: 80vw;
        height: 30vw;
        padding: 3.5vw;
    }

    .spop_checkpoint_sect2 {
        width: 80vw;
        height: 43vw;
        padding: 3.5vw;
    }

    .spop_checkpoint_sect3 {
        width: 80vw;
        height: 64vw;
        padding: 3.5vw;
    }

    .checkpoint_title {
        font-size: 5.5vw;
    }

    .checkpoint_body {
        font-size: 4vw;
    }

    .register_title {
        font-size: 6.5vw;
    }

    .register_normal_text {
        font-size: 4vw;
    }

    .register_list_title {
        font-size: 6vw;
    }

    .register_dot_top_container {
        font-size: 4vw;
    }

    .register_dot_bottom_container {
        font-size: 4vw;
    }

    .dot {
        width: 1.4vw;
    }

    .steps_image {
        width: 5vw;
    }

    .steps_text {
        font-size: 4vw;
    }

    .register_info_img {
        width: 90vw;
    }

    .inverted_chevron {
        font-size: 6.5vw;
    }

    .steps_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .steps_inner_container {
        margin: 1vw 0;
    }

    .inverted_chevron {
        display: none;
    }

    .register_title {
        margin-top: 8vw;
    }
}

.technology_container {
    background-color: #374351;
    position: relative;
    padding-bottom: 100px;
}

.technology_title {
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    width: auto !important;
    color: white;
}

.technology_title_container {
    margin: 100px 0 !important; 
    justify-content: center;
}

.tech_icon_container {
    padding: 72px 212px !important;
    justify-content: center;
}


.tech_icon_container_ {
    padding: 72px 443px !important;
}


.tech_icon_ {
    text-align: center;
    color: white;
    padding: 0px 10px !important;
    opacity: 0;
    transform: translateY(20px);
}

.tech_show1 {
    opacity: 1;
    transform: translateY(0px);
    transition: all 1s;
}

.tech_show2 {
    opacity: 1;
    transform: translateY(0px);
    transition: all 1s;
    transition-delay: 0.1s;
}

.tech_show3 {
    opacity: 1;
    transform: translateY(0px);
    transition: all 1s;
    transition-delay: 0.2s;
}

.tech_icon_ img {
    width: 100px;
    height: 100px;
}

.tech_icon_:nth-child(2) img {
    width: "auto";

}

.icon_title_ {
    font-size: 20px;
    font-weight: 400;
    margin: 20px 0;
    color: #000;
}

.icon_content {
    font-size: 20px;
    font-weight: 200;
    margin: 20px 0;
}

@media only screen and (max-width: 1800px) {
    .icon_title_ {
        font-size: 18px;
        color:#000
    }
    
    .icon_content {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1650px) {
    .icon_title_ {
        font-size: 15px;
        color:#000
    }
    
    .icon_content {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1500px) {
    .tech_icon_container {
        padding: 40px 13px !important;
    }
    .sol_img{
        padding: 18px 367px
    }
    .tech_icon_container_{
        padding: 40px 166px !important;
    }
}

@media only screen and (max-width: 1050px) {
    .tech_icon_container br {
        display: none;
    }
    .tech_icon_container_ br{
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .tech_icon_container .col-4 {
        width: 100%;
    }
    .tech_icon_container_ .col-4 {
        width: 100%;
    }
    .technology_container {
        padding-bottom: 100px;
    }
    .tech_icon_ {
        padding: 30px 180px !important;
    }
}

@media only screen and (max-width: 700px) {
    .tech_icon_ {
        padding: 30px 10px !important;
    }

    .technology_title_container {
        margin: 50px 0 !important;
    }
}









.eugene_container {
    width: 100%;
    display: flex;
}

.eugene_background {
    background-image: url('../../../../public/assets/eugene/eugene_top_background.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    position: relative;
}

.eugene_top_title_img {
    position: absolute;
    top: 12vw;
    left: 18vw;
    width: 25vw;
}

.eugene_top_title_text {
    width: 30vw;
    position: absolute;
    left: 18vw;
    top: 16vw;
    font-size: 1vw;
}

.eugene_intro_title {
    margin-left: auto;
    margin-right: auto;
    width: 10vw;
    margin-top: 4vw;
}

.intro_inner_container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
    justify-content: space-around;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.eugene_center_text {
    text-align: center;
    margin-top: 3vw;
    font-size: 1vw;
}

.eugene_hashtag {
    font-size: 1vw;
    color: #990000
}

.eugene_steps {
    width: 12vw;
    margin-left: auto;
}

.eugene_checkpoint_background {
    background-image: url('../../../../public/assets/eugene/eugene_checkpoint_background.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 24vw;
    position: relative;
}

.eugene_checkpoint_sect1 {
    background-color: white;
    border-radius: 1vw;
    width: 23vw;
    height: 8vw;
    position: relative;
    padding: 1.5vw;
}

.eugene_checkpoint_sect2 {
    background-color: white;
    border-radius: 1vw;
    width: 23vw;
    height: 9vw;
    position: relative;
    padding: 1.5vw;
}

.eugene_checkpoint_sect3 {
    background-color: white;
    border-radius: 1vw;
    width: 23vw;
    height: 18vw;
    position: relative;
    padding: 1.5vw;
}

.checkpoint_title {
    font-family: 'GmarketBold';
    color: #1b54bc;
    font-size: 1.6vw;
}

.checkpoint_body {
    font-size: 1vw;
}

.checkpoint_right_container {
    display: flex;
}

.eugene_service_intro {
    background-color: #990000;
    width: 10vw;
    font-size: 1.3vw;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 40px;
    font-weight: bold;
    padding: 0.2vw 0;
    margin-top: 2vw;
}

.text_title {
    font-family: 'GmarketBold';
    text-align: center;
    font-size: 2.2vw;
    margin-top: 2vw;
}

.three_container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
    justify-content: space-around;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.three_container_ind {
    width: 14.7vw;
    text-align: center;
}

.three_container_text {
    text-align: center;
    font-size: 1vw;
    margin-top: 1vw;
}

.checkpoint_text {
    color: white;
    font-size: 2.2vw;
    font-family: 'GmarketBold';
}

.checkpoint_inner_container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 59vw;
    justify-content: space-around;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.checkpoint_right_inner_container {
    margin-top: 2vw; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    height: 18vw; 
    width: 24vw;
}

.eugene_register_container {
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vw;
}

.register_title {
    font-family: 'GmarketBold';
    color: #394350;
    font-size: 1.5vw;
}

.register_normal_text {
    font-size: 1vw; 
    margin-top: 0.3vw
}

.register_list_title {
    margin-top: 1vw; 
    font-size: 1.5vw; 
    font-weight: bold;
}

.register_dot_top_container {
    display: flex; 
    align-items: center; 
    margin-top: 0.6vw;
    font-size: 1vw;
}

.register_dot_bottom_container {
    display: flex; 
    align-items: center; 
    margin-top: 0.3vw;
    font-size: 1vw;
}

.steps_image {
    width: 1.3vw; 
    height: 100%; 
    margin-right: 10px;
}

.steps_text {
    margin-right: 10px; 
    font-size: 1vw;
}

.dot {
    width: 0.4vw; 
    height: 100%;
}

.register_info_img {
    width: 48vw; 
    margin-top: 1.5vw;
}

.inverted_chevron {
    margin-right: 10px; 
    color: #1f2b49;
    font-weight: bold;
    font-size: 1.5vw;
}

.steps_container {
    font-size: 1vw; 
    margin-top: 0.6vw; 
    display: flex; 
    align-items: center;
}

.steps_inner_container {
    display: flex;
    align-items: center;
}

.instructions_container {
    display: flex;
    width: 70vw;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vw;
    margin-bottom: 10vw;
}

.instructions_inner_container {
    display: flex;
    width: 25vw;
    align-items: center;
}

.instructions_title {
    color: #990000;
    font-size: 1.5vw;
    font-weight: bold;
}

.instructions_body {
    font-size: 1vw;
}

.instructions_border {
    width: 10vw;
    border-top: solid 3px #990000;
    margin-left: 0.5vw;
}

.instructions_margin {
    padding: 0 10px;
}

@media only screen and (max-width: 1000px) {
    .eugene_top_title_img {
        top: 9vw;
        width: 64vw;
        left: 9vw;
    }

    .eugene_top_title_text {
        top: 17vw;
        width: 52vw;
        font-size: 2vw;
        left: 9vw;
    }

    .eugene_service_intro {
        background-color: #990000;
        width: 20vw;
        font-size: 2.3vw;
        color: white;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        border-radius: 40px;
        font-weight: bold;
        padding: 0.2vw 0;
        margin-top: 3vw;
    }

    .eugene_checkpoint_background {       
        height: 510px;
        background-size: cover;
        background-position: center;
    }

    .checkpoint_inner_container {
        flex-direction: column;
        width: 80vw;
    }

    .checkpoint_text {
        text-align: center;
        font-size: 3vw;
        margin-top: 3vw;
    }

    .checkpoint_right_container {
        margin-left: auto;
        margin-right: auto;
    }
    
    .checkpoint_right_inner_container {
        width: 37vw;
        height: 35vw;
    }

    .eugene_checkpoint_sect1 {
        width: 35vw;
        height: 17vw;
    }

    .eugene_checkpoint_sect2 {
        width: 35vw;
        height: 17vw;
    }

    .eugene_checkpoint_sect3 {
        width: 35vw;
        height: 35vw;
    }
    
    .checkpoint_title {
        font-size: 2.5vw;
    }

    .checkpoint_body {
        font-size: 1.5vw;
    }

    .eugene_register_container {
        width: 80vw;
    }

    .register_title {
        font-size: 2.5vw;
    }

    .register_normal_text {
        font-size: 1.6vw;
    }

    .register_list_title {
        font-size: 2vw;
    }

    .register_dot_top_container {
        font-size: 1.5vw;
    }

    .register_dot_bottom_container {
        font-size: 1.5vw;
    }

    .steps_image {
        width: 2vw;
    }

    .steps_text {
        font-size: 1.5vw;
    }

    .instructions_container {
        width: 90vw;
    }

    .instructions_inner_container {
        width: 30vw;
    }

    .text_title {
        font-size: 3vw;
    }

    .eugene_center_text {
        font-size: 2vw;
    }

    .eugene_hashtag {
        font-size: 2vw;
    }

    .three_container_text {
        font-size: 2vw;
    }

    .three_container {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        width: 70vw;
        justify-content: space-around;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }
    
    .three_container_ind {
        width: 100%;
        text-align: center;
    }

    .instructions_title {
        font-size: 2.5vw;
    }

    .instructions_body {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 780px) {
    .eugene_register_container {
        width: 95vw;
        padding: 0 3vw;
    }
}

@media only screen and (max-width: 660px) {
    .eugene_background {
        background-size: cover;
        height: 260px;
        background-position: center;
    }

    .eugene_top_title_img {
        top: 3vw;
        width: 64vw;
        left: 9vw;
    }

    .eugene_top_title_text {
        top: 10vw;
        width: 52vw;
        font-size: 2vw;
        left: 9vw;
    }

    .eugene_service_intro {
        background-color: #990000;
        width: 30vw;
        font-size: 3.3vw;
        color: white;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        border-radius: 40px;
        font-weight: bold;
        padding: 0.2vw 0;
        margin-top: 7vw;
    }

    .text_title {
        font-size: 4.2vw;
        margin-top: 4vw;
    }

    .eugene_intro_title {
        width: 22vw;
    }

    .eugene_center_text {
        font-size: 2vw;
    }

    .eugene_hashtag {
        font-size: 2vw;
    }

    .mobile_container {
        height: 450px;
    }

    .three_container {
        width: 75vw;
        margin-top: 12vw;
    }

    .three_container_ind {
        width: 25vw;
        text-align: center;
    }

    .eugene_steps {
        width: 20vw;
    }

    .three_container_text {
        font-size: 2vw;
    }

    .eugene_checkpoint_background {
        height: 330px;
    }

    .instructions_container {
        flex-direction: column;
    }

    .instructions_title {
        font-size: 3.5vw;
    }

    .instructions_inner_container {
        width: 90vw;
    }

    .instructions_body {
        font-size: 2.2vw;
    }

    .instructions_border {
        width: 30vw;
    }

    .instructions_margin {
        margin-bottom: 3vw;
    }
}

@media only screen and (max-width: 660px) {
    .eugene_top_title_img {
        top: 9vw;
    }

    .eugene_top_title_text {
        top: 17vw;
    }
}

@media only screen and (max-width: 500px) {
    .eugene_top_title_text {
        font-size: 4vw;
        width: 70vw;
    }

    .eugene_background {
        height: 290px;
    }

    .intro_inner_container {
        width: 90vw;
        margin-bottom: 40px;
    }
    
    .mobile_container {
        height: 390px;
        padding-top: 30px;
    }

    .three_container {
        width: 95vw;
    }

    .three_container_ind {
        width: 32vw;
    }

    .eugene_service_intro {
        width: 45vw;
        font-size: 5.3vw;
    }

    .text_title {
        font-size: 6.2vw;
    }

    .eugene_intro_title {
        width: 40vw;
    }

    .eugene_center_text {
        font-size: 4vw;
        padding: 0 2vw;
    }

    .eugene_hashtag {
        font-size: 4vw;
    }

    .eugene_steps {
        width: 27vw;
    }

    .three_container_text {
        font-size: 4vw;
    }

    .three_container {
        margin-top: 7vw;
    }

    .checkpoint_text {
        font-size: 6.2vw;
    }

    .checkpoint_right_container {
        flex-direction: column;
    }

    .eugene_checkpoint_background {
        height: 750px;
    }

    .checkpoint_inner_container {
        justify-content: center;
    }

    .checkpoint_right_inner_container {
        width: 80vw;
        height: 75vw;
    }

    .eugene_checkpoint_sect1 {
        width: 80vw;
        height: 30vw;
        padding: 3.5vw;
    }

    .eugene_checkpoint_sect2 {
        width: 80vw;
        height: 43vw;
        padding: 3.5vw;
    }

    .eugene_checkpoint_sect3 {
        width: 80vw;
        height: 64vw;
        padding: 3.5vw;
    }

    .checkpoint_title {
        font-size: 5.5vw;
    }

    .checkpoint_body {
        font-size: 4.5vw;
    }

    .register_title {
        font-size: 6.5vw;
    }

    .register_normal_text {
        font-size: 4vw;
    }

    .register_list_title {
        font-size: 6vw;
    }

    .register_dot_top_container {
        font-size: 4.5vw;
    }

    .register_dot_bottom_container {
        font-size: 4.5vw;
    }

    .dot {
        width: 1.4vw;
    }

    .steps_image {
        width: 5vw;
    }

    .steps_text {
        font-size: 4.6vw;
    }

    .register_info_img {
        width: 90vw;
    }

    .inverted_chevron {
        font-size: 6.5vw;
    }

    .steps_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .steps_inner_container {
        margin: 1vw 0;
    }

    .inverted_chevron {
        display: none;
    }

    .register_title {
        margin-top: 8vw;
    }

    .instructions_title {
        font-size: 6vw;
    }

    .instructions_body {
        font-size: 4vw;
    }
}

.imgDesign {
    max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
    .imgDesign {
        max-width: 900px;
    }
}