.news_container {
  z-index: 2;
  position: relative;
}

.news_title_container {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.news_title {
    text-align: center;
    color: #990000;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    width: 80px;
}

.news_slide_container {
    width: 2000px;
    margin-left: auto;
    margin-right: auto;
}

.news_slide {
    height: 600px;
    width: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    background-color: white;
    transition: all 0.5s;
    position: relative;
}

/* @media only screen and (min-width: 900px)
{
  .news_slide:hover {
    transform: translateY(-20px);
    transition: all 0.5s;
  }
} */

.news_image {
    text-align: center;
}

.news_image img {
    height: 240px;
}

.news_content {
    padding: 30px;
    font-size: 14px;
}

/* CSS */
.news_link_button {
    background-color: #990000;
    border: 2px solid #422800;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 10px;
    padding: 0 18px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    position: absolute;
    right: 40px;
    bottom: 20px;
  }
  
  .news_link_button:hover {
    background-color: #630606;
  }
  
  @media (min-width: 768px) {
    .news_link_button {
      min-width: 40px;
      padding: 0 5px;
    }
  }
  @media only screen and (max-width: 3000px) {
   
    
    .news_content_patent{
      padding: 20px;
      height: 548px;
    }
  }
  @media only screen and (max-width: 2000px) {
    .news_slide_container {
      width: 1600px;
    }

    .news_slide {
      height: 530px;
      width: 450px;
    }

    .news_image img {
      height: 160px;
    }

    .news_content {
      padding: 20px;
 
    }
    .news_content_patent{
      padding: 20px;
      height: 548px;
    }
  }

  @media only screen and (max-width: 1600px) {
    .news_slide_container {
      width: 1200px;
    }

    .news_slide {
      height: 510px;
      width: 406px;
    }

    .news_image img {
      height: 140px;
    }

    .news_content {
      padding: 10px;
    }
    .news_content_patent{
      
      height: 527px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .news_slide_container {
      width: 800px;
    }

    .news_slide {
      height: 440px;
      width: 350px;
    }

    .news_image img {
      height: 100px;
    }

    .news_content {
      padding: 10px;
      font-size: 12px;
    }
    .news_content_patent{
      padding: 20px;
      height: 527px;
    }
  }

  @media only screen and (max-width: 800px) {
    .news_slide_container {
      width: 480px;
    }

    .news_title_container {
      margin-bottom: 0px;
    }
    .news_content_patent{
      padding: 20px;
      height: 500px;
    }
  }

  @media only screen and (max-width: 600px) {
    .news_slide_container {
      width: 350px;
    }

    .news_slide {
      height: 340px;
      width: 250px;
    }

    .news_image img {
      height: 70px;
    }

    .news_content {
      padding: 10px;
      font-size: 10px;
    }

    .news_content h6 {
      font-size: 14px;
    }

    .news_link_button {
      right: 30px;
      bottom: 10px;
    }
    .news_content_patent{
      padding: 20px;
      height: 500px!important;
    }
  }

  .swiper_container {
    width: 350px;
  }

  @media only screen and (min-width: 600px) {
    .swiper_container {
      width: 480px;
    }
  }

  @media only screen and (min-width: 800px) {
    .swiper_container {
      width: 800px;
    }
  }


  @media only screen and (min-width: 1200px) {
    .swiper_container {
      width: 1200px;
    }
  }

  @media only screen and (min-width: 1600px) {
    .swiper_container {
      width: 1600px;
    }
  }

  @media only screen and (min-width: 2000px) {
    .swiper_container {
      width: 2000px;
    }
  }