.enterprise_dbinvestment_dropdown{


    /* margin-top: 30px; */
    
}

.dt_dropdown{
 
    width: 102px!important;
/*      height: 41px;
    box-sizing: border-box;*/
    font-size: 15px!important; 

    padding: 8px 26px 7px 10px!important;
}