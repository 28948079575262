.mission_container {
    background-image: url('../../../public/assets/section-2--img.png');
    width: 100%;
    background-size: cover;
    height: 100%;
    margin-top: 130px;
    z-index: -2;
}

.mission_grid_container {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 500px;
 
}

.mission_grid_left {
    grid-column: 1;
    grid-row: 1;
}

.mission_grid_right {
    grid-column: 2;
    grid-row: 1;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.mission_title {
    margin-bottom: 40px;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    z-index: 100;
    color: white;
    opacity: 0;
    transform: translateY(20px);
}

.mission_content {
    font-weight: 200;
    font-size: 20px;
    opacity: 0;
    transform: translateY(20px);
}

.mission_show {
    opacity: 1;
    transform: translateY(0px);
    transition: all 1s;
}
@media only screen and (max-width: 2000px)
{
    .mission_container {
       
      
        margin-top:0px;
     
    }
}
@media only screen and (max-width: 1350px) {
    .mission_container {
       
      
        margin-top: 0px;
     
    }
}

@media only screen and (max-width: 900px) {

    .mission_grid_container {
        display: flex;
        grid-template-columns: none;
        justify-content: center;
        align-items: center;

        margin-top: 0;
    }

    .mission_grid_container .col-6 {
        display: none;
    }
}
@media only screen and (max-width: 800px) {
    .mission_container {
        
        margin-top: -88px;
    }

}
