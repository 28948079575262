.footer_container {
    background-image: url('../../../public/assets/section-8-9-bg.png');
    width: 100%;
    background-size: cover;
    height: 100%;
    padding-top: 60px;
    z-index: 0;
    position: relative;
    padding-bottom: 100px;
}

.footer_grid_container {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 400px;
}

.footer_title {
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    width: auto !important;
    margin-top: 50px;
    margin-bottom: 50px;
}

.footer_grid_left {
    grid-column: 1;
    grid-row: 1;
}

.footer_grid_right {
    grid-column: 2;
    grid-row: 1;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footer_content {
    font-weight: 200;
    font-size: 20px;
}

.footer_body_title {
    font-size: 18px;
    color: white;
    position: relative;
    padding-left: 20px;
}

.footer_body_title:before {
    content: '';
    background: url('../../../public/assets/list-square.png');
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    z-index: -1;
    top: 10px;
}

.footer_body_content {
    font-size: 18px;
    color: white;
    position: relative;
    padding-left: 20px;
    font-weight: 300;
    padding: 30px 0;
}

.footer_grid_button {
    margin-top: 60px;
}

.footer_button {
    width: 227px;
    height: 46px;
    background-color: #990000;
    color: white;
    text-align: center;
    font-size: 17px;
    line-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.divider_2 {
    border-top: 1px solid white;
    margin: 80px 0;
}

.footer_body_content_und {
    font-size: 20px;
    color: white;
    position: relative;
    /* border-bottom: 2px solid #7ec2be; */
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.footer_container .row {
    display: flex;
    justify-content: center;
}

.footer_body_content_und.block_1 {
    width: 225px;
    cursor: pointer;
}

.footer_body_content_und.block_2 {
    width: 220px;
}

.footer_body_content_und.block_3 {
    width: 400px;
}

@media only screen and (max-width: 1400px) {
    .footer_body_content_und {
        font-size: 15px;
    }
    .footer_body_content_und.block_1 {
        width: 170px;
    }
    
    .footer_body_content_und.block_2 {
        width: 170px;
    }
    
    .footer_body_content_und.block_3 {
        width: 300px;
    }
}

@media only screen and (max-width: 850px) {
    .service_contact .col-4 {
        width: 100%;
    }
}



.social-icons {
    display: flex;
    margin-top: 10px;
  }
  

  
  .icon{
    font-size: 30px;
    margin-right: 10px; 
}
  /* Media queries for responsive design */
  
  @media (max-width: 768px) {
    .social-icons {
        display: flex;
        margin-top: 10px;
    }
  
    .icon{
        font-size: 30px;
        margin-right: 10px; 
    }
   
  }
  