.xpercent_grid_container {
    height: auto;
    justify-content: center;
    padding-bottom: 200px;
    /* background-color: #374351; */
    /* position: relative; */
}

.xpercent_grid_left {
    color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.xpercent_title {
    margin-bottom: 30px;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    z-index: 100;
    text-align: center;
    color: #16334f;
    opacity: 0;
    transform: translateY(20px);
    margin-top: 50px;
}

.xpercent_content {
   
    font-size: 20px;
    opacity: 0;
    transform: translateY(20px);
}

.xpercent_grid_button {
    margin-top: 60px;
}

.xpercent_button {
    width: 227px;
    height: 46px;
    background-color: #990000;
    color: white;
    text-align: center;
    font-size: 20px;
    line-height: 46px;
    margin-left: calc(50% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transform: translateY(20px);
}

.xpercent_show {
    opacity: 1;
    transform: translateY(0px);
    transition: all 1s;
}

.xpercent_grid_right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.xpercent_grid_right img {
    width: 500px;
    height: 500px;
}

.xpercent_text {
    font-size: 14px!important;
    color: black !important;;
    height: 80%;
    margin-bottom: 10px!important;
    width: "100%";
}

.service_button {
    font-size: 15px;
    text-align: center;
    background-color: #990000;
    color: white;
    padding: 10px 0;
    margin-top: 10px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.solution_small_logo{
    width: 72px!important;
 
   
}

.solution_small_logo_bigger{
    width: 90px!important;
    text-align: center;

}

@media only screen and (max-width: 1055px) {

    .xpercent_text {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1050px) {
    .xpercent_grid_right img {
        width: 400px;
        height: 400px;
    }
}


@media only screen and (max-width: 990px) {
    .xpercent_text {
        font-size: 12px;
    }

    
}

@media only screen and (max-width: 900px) {
    .xpercent_grid_right {
        display: none;
    }
    .xpercent_grid_left {
        width: 83.33333333% !important;
    }
    .xpercent_title {
        margin-left: 0;
    }
    .xpercent_content {
        margin-left: 0;
    }
    .xpercent_button {
        margin-left: 0;
    }
}


@media screen and (min-width: 768px) and (max-width: 1096px) and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {

    .solution_small_logo_bigger {
        width: 86px!important;
    }
    .solution_small_logo{
        width: 68px!important;
    }
    .service_con_height{
        /* height: 100px!important; */
        flex-direction: column!important;
        
    }
    .solution_img_margin_top{
        margin-top: 10px !important;
    }
    
  
  
  }


@media only screen and (max-width: 860px) {
    .xpercent_text {
        font-size: 13px;
    }
}

@media only screen and (max-width: 770px) {
    .xpercent_text {
        font-size: 14px;
    }
    .video_containter {
        margin-top: 413px!important;
    }
/* .xpercent_small_logo{
    width: 15%!important
} */
.xpercent_service_con_margin{
    margin-bottom: 16px;
}
}

@media only screen and (max-width: 720px) {
    .xpercent_grid_container {
        max-width: 80%;
    }
    .xpercent_text {
        font-size: 15px;
    }
    .signal_class {
        margin-top: 30px;
    }
}