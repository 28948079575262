@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

@font-face {
  font-family: 'GmarketMedium';
  src: local('GmarketMedium'), url(./fonts/GmarketSansTTFMedium.ttf) format('truetype');
}

@font-face {
  font-family: 'GmarketLight';
  src: local('GmarketLight'), url(./fonts/GmarketSansTTFLight.ttf) format('truetype');
}

@font-face {
  font-family: 'GmarketBold';
  src: local('GmarketBold'), url(./fonts/GmarketSansTTFBold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
