@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}

.App{
	display: flex;
	flex-direction: column;
	width: 100%;
}

.page{
	flex-basis: 100%;
	transition: margin-left 1s;
    background-color: #f1f1f1;
}

.page-with-navbar{
	margin-left: 250px;
}

@media only screen and (max-width: 768px){
	.page-with-navbar{
		width: 100%;
		margin-left: 0;
	}

    .mobile-ratb-nav{
		display: flex;
		align-items: center;
	}
	aside{
		width: 70%;
		top: 38px;
		min-height: calc(100vh - 38px);
	}

	.ratb-nav-btn{
		display: none;
	}

}

.portfolio-header {
    margin: 20px;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    justify-content: space-between;
    display: flex;
    flex-direction: row
}

.portfolio-body {
    margin: 20px;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}

.portfolio-header-head {
    font-weight: bold;
    background-color: #c4c4c4;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100px;
    text-align: center;
}

.portfolio-header-button {
    font-weight: bold;
    background-color: rgb(255, 165, 0);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.portfolio-header-select {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

@media only screen and (max-width: 1060px){
    .portfolio-header {
        margin: 20px;
        background-color: white;
        padding: 5px;
        border-radius: 5px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .portfolio-header-head {
        width: 200px;   
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 20px;
    }
    
    .portfolio-header-button {
        width: 200px;   
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }   

    .portfolio-header-select {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }

    .inner-select {
        margin-bottom: 20px;
    }
}