.eugene_container {
    width: 100%;
    display: flex;
}

.eugene_background {
    background-image: url('../../../public/assets/eugene/eugene_top_background.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    position: relative;
}

.eugene_top_title_img {
    position: absolute;
    top: 12vw;
    left: 18vw;
    width: 25vw;
}

.eugene_top_title_text {
    width: 30vw;
    position: absolute;
    left: 18vw;
    top: 16vw;
    font-size: 1vw;
}

.eugene_intro_title {
    margin-left: auto;
    margin-right: auto;
    width: 10vw;
    margin-top: 4vw;
}

.intro_inner_container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
    justify-content: space-around;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.eugene_center_text {
    text-align: center;
    margin-top: 3vw;
    font-size: 1vw;
}

.eugene_hashtag {
    font-size: 1vw;
    color: #990000
}

.eugene_steps {
    width: 12vw;
    margin-left: auto;
}

.eugene_checkpoint_background {
    background-image: url('../../../public/assets/eugene/eugene_checkpoint_background.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 24vw;
    position: relative;
}

.eugene_checkpoint_sect1 {
    background-color: white;
    border-radius: 1vw;
    width: 23vw;
    height: 8vw;
    position: relative;
    padding: 1.5vw;
}

.eugene_checkpoint_sect2 {
    background-color: white;
    border-radius: 1vw;
    width: 23vw;
    height: 9vw;
    position: relative;
    padding: 1.5vw;
}

.eugene_checkpoint_sect3 {
    background-color: white;
    border-radius: 1vw;
    width: 23vw;
    height: 18vw;
    position: relative;
    padding: 1.5vw;
}

.checkpoint_title {
    font-family: 'GmarketBold';
    color: #1b54bc;
    font-size: 1.6vw;
}

.checkpoint_body {
    font-size: 1vw;
}

.checkpoint_right_container {
    display: flex;
}

.eugene_service_intro {
    background-color: #990000;
    width: 10vw;
    font-size: 1.3vw;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 40px;
    font-weight: bold;
    padding: 0.2vw 0;
    margin-top: 2vw;
}

.text_title {
    font-family: 'GmarketBold';
    text-align: center;
    font-size: 2.2vw;
    margin-top: 2vw;
}

.three_container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
    justify-content: space-around;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.three_container_ind {
    width: 14.7vw;
    text-align: center;
}

.three_container_text {
    text-align: center;
    font-size: 1vw;
    margin-top: 1vw;
}

.checkpoint_text {
    color: white;
    font-size: 2.2vw;
    font-family: 'GmarketBold';
}

.checkpoint_inner_container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 59vw;
    justify-content: space-around;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.checkpoint_right_inner_container {
    margin-top: 2vw; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    height: 18vw; 
    width: 24vw;
}

.eugene_register_container {
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vw;
}

.register_title {
    font-family: 'GmarketBold';
    color: #394350;
    font-size: 1.5vw;
}

.register_normal_text {
    font-size: 1vw; 
    margin-top: 0.3vw
}

.register_list_title {
    margin-top: 1vw; 
    font-size: 1.5vw; 
    font-weight: bold;
}

.register_dot_top_container {
    display: flex; 
    align-items: center; 
    margin-top: 0.6vw;
    font-size: 1vw;
}

.register_dot_bottom_container {
    display: flex; 
    align-items: center; 
    margin-top: 0.3vw;
    font-size: 1vw;
}

.steps_image {
    width: 1.3vw; 
    height: 100%; 
    margin-right: 10px;
}

.steps_text {
    margin-right: 10px; 
    font-size: 1vw;
}

.dot {
    width: 0.4vw; 
    height: 100%;
}

.register_info_img {
    width: 48vw; 
    margin-top: 1.5vw;
}

.inverted_chevron {
    margin-right: 10px; 
    color: #1f2b49;
    font-weight: bold;
    font-size: 1.5vw;
}

.steps_container {
    font-size: 1vw; 
    margin-top: 0.6vw; 
    display: flex; 
    align-items: center;
}

.steps_inner_container {
    display: flex;
    align-items: center;
}

.instructions_container {
    display: flex;
    width: 70vw;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vw;
    margin-bottom: 10vw;
}

.instructions_inner_container {
    display: flex;
    width: 25vw;
    align-items: center;
}

.instructions_title {
    color: #990000;
    font-size: 1.5vw;
    font-weight: bold;
}

.instructions_body {
    font-size: 1vw;
}

.instructions_border {
    width: 10vw;
    border-top: solid 3px #990000;
    margin-left: 0.5vw;
}

.instructions_margin {
    padding: 0 10px;
}

@media only screen and (max-width: 1000px) {
    .eugene_top_title_img {
        top: 9vw;
        width: 64vw;
        left: 9vw;
    }

    .eugene_top_title_text {
        top: 17vw;
        width: 52vw;
        font-size: 2vw;
        left: 9vw;
    }

    .eugene_service_intro {
        background-color: #990000;
        width: 20vw;
        font-size: 2.3vw;
        color: white;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        border-radius: 40px;
        font-weight: bold;
        padding: 0.2vw 0;
        margin-top: 3vw;
    }

    .eugene_checkpoint_background {       
        height: 510px;
        background-size: cover;
        background-position: center;
    }

    .checkpoint_inner_container {
        flex-direction: column;
        width: 80vw;
    }

    .checkpoint_text {
        text-align: center;
        font-size: 3vw;
        margin-top: 3vw;
    }

    .checkpoint_right_container {
        margin-left: auto;
        margin-right: auto;
    }
    
    .checkpoint_right_inner_container {
        width: 37vw;
        height: 35vw;
    }

    .eugene_checkpoint_sect1 {
        width: 35vw;
        height: 17vw;
    }

    .eugene_checkpoint_sect2 {
        width: 35vw;
        height: 17vw;
    }

    .eugene_checkpoint_sect3 {
        width: 35vw;
        height: 35vw;
    }
    
    .checkpoint_title {
        font-size: 2.5vw;
    }

    .checkpoint_body {
        font-size: 1.5vw;
    }

    .eugene_register_container {
        width: 80vw;
    }

    .register_title {
        font-size: 2.5vw;
    }

    .register_normal_text {
        font-size: 1.6vw;
    }

    .register_list_title {
        font-size: 2vw;
    }

    .register_dot_top_container {
        font-size: 1.5vw;
    }

    .register_dot_bottom_container {
        font-size: 1.5vw;
    }

    .steps_image {
        width: 2vw;
    }

    .steps_text {
        font-size: 1.5vw;
    }

    .instructions_container {
        width: 90vw;
    }

    .instructions_inner_container {
        width: 30vw;
    }

    .text_title {
        font-size: 3vw;
    }

    .eugene_center_text {
        font-size: 2vw;
    }

    .eugene_hashtag {
        font-size: 2vw;
    }

    .three_container_text {
        font-size: 2vw;
    }

    .three_container {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        width: 70vw;
        justify-content: space-around;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }
    
    .three_container_ind {
        width: 100%;
        text-align: center;
    }

    .instructions_title {
        font-size: 2.5vw;
    }

    .instructions_body {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 780px) {
    .eugene_register_container {
        width: 95vw;
        padding: 0 3vw;
    }
}

@media only screen and (max-width: 660px) {
    .eugene_background {
        background-size: cover;
        height: 260px;
        background-position: center;
    }

    .eugene_top_title_img {
        top: 3vw;
        width: 64vw;
        left: 9vw;
    }

    .eugene_top_title_text {
        top: 10vw;
        width: 52vw;
        font-size: 2vw;
        left: 9vw;
    }

    .eugene_service_intro {
        background-color: #990000;
        width: 30vw;
        font-size: 3.3vw;
        color: white;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        border-radius: 40px;
        font-weight: bold;
        padding: 0.2vw 0;
        margin-top: 7vw;
    }

    .text_title {
        font-size: 4.2vw;
        margin-top: 4vw;
    }

    .eugene_intro_title {
        width: 22vw;
    }

    .eugene_center_text {
        font-size: 2vw;
    }

    .eugene_hashtag {
        font-size: 2vw;
    }

    .mobile_container {
        height: 450px;
    }

    .three_container {
        width: 75vw;
        margin-top: 12vw;
    }

    .three_container_ind {
        width: 25vw;
        text-align: center;
    }

    .eugene_steps {
        width: 20vw;
    }

    .three_container_text {
        font-size: 2vw;
    }

    .eugene_checkpoint_background {
        height: 330px;
    }

    .instructions_container {
        flex-direction: column;
    }

    .instructions_title {
        font-size: 3.5vw;
    }

    .instructions_inner_container {
        width: 90vw;
    }

    .instructions_body {
        font-size: 2.2vw;
    }

    .instructions_border {
        width: 30vw;
    }

    .instructions_margin {
        margin-bottom: 3vw;
    }
}

@media only screen and (max-width: 660px) {
    .eugene_top_title_img {
        top: 9vw;
    }

    .eugene_top_title_text {
        top: 17vw;
    }
}

@media only screen and (max-width: 500px) {
    .eugene_top_title_text {
        font-size: 4vw;
        width: 70vw;
    }

    .eugene_background {
        height: 290px;
    }

    .intro_inner_container {
        width: 90vw;
        margin-bottom: 40px;
    }
    
    .mobile_container {
        height: 390px;
        padding-top: 30px;
    }

    .three_container {
        width: 95vw;
    }

    .three_container_ind {
        width: 32vw;
    }

    .eugene_service_intro {
        width: 45vw;
        font-size: 5.3vw;
    }

    .text_title {
        font-size: 6.2vw;
    }

    .eugene_intro_title {
        width: 40vw;
    }

    .eugene_center_text {
        font-size: 4vw;
        padding: 0 2vw;
    }

    .eugene_hashtag {
        font-size: 4vw;
    }

    .eugene_steps {
        width: 27vw;
    }

    .three_container_text {
        font-size: 4vw;
    }

    .three_container {
        margin-top: 7vw;
    }

    .checkpoint_text {
        font-size: 6.2vw;
    }

    .checkpoint_right_container {
        flex-direction: column;
    }

    .eugene_checkpoint_background {
        height: 750px;
    }

    .checkpoint_inner_container {
        justify-content: center;
    }

    .checkpoint_right_inner_container {
        width: 80vw;
        height: 75vw;
    }

    .eugene_checkpoint_sect1 {
        width: 80vw;
        height: 30vw;
        padding: 3.5vw;
    }

    .eugene_checkpoint_sect2 {
        width: 80vw;
        height: 43vw;
        padding: 3.5vw;
    }

    .eugene_checkpoint_sect3 {
        width: 80vw;
        height: 64vw;
        padding: 3.5vw;
    }

    .checkpoint_title {
        font-size: 5.5vw;
    }

    .checkpoint_body {
        font-size: 4.5vw;
    }

    .register_title {
        font-size: 6.5vw;
    }

    .register_normal_text {
        font-size: 4.6vw;
    }

    .register_list_title {
        font-size: 6vw;
    }

    .register_dot_top_container {
        font-size: 4.5vw;
    }

    .register_dot_bottom_container {
        font-size: 4.5vw;
    }

    .dot {
        width: 1.4vw;
    }

    .steps_image {
        width: 5vw;
    }

    .steps_text {
        font-size: 4.6vw;
    }

    .register_info_img {
        width: 90vw;
    }

    .inverted_chevron {
        font-size: 6.5vw;
    }

    .steps_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .steps_inner_container {
        margin: 1vw 0;
    }

    .inverted_chevron {
        display: none;
    }

    .register_title {
        margin-top: 8vw;
    }

    .instructions_title {
        font-size: 6vw;
    }

    .instructions_body {
        font-size: 4vw;
    }
}