.main_container2 {
    height: 900px;
    display: flex;
    position: relative;
}

.left {
    background-color: white;
    width: 15%;
}

.right {
    background-color: #333B58;
}

.image_container {
    position: absolute;
    top: 130px;
    left: 0;
    width: calc(100%);
    background-image: url("../../../public/assets/main-hero--img.png");
    background-size: cover;
    height: 100%;
    display: flex;
    
    transition: all 0.5s;
}

.image_content {
    color: white;
    padding: 200px 0;
}

.content_img {
    width: 267px;
    height: 30px;
}

.main_container_title {
    font-weight: 500;
    font-size: 40px;
    padding: 15px 0 50px 0;
    opacity: 0;
    transform: translateY(20px);
}

.main_container_content {
    font-weight: 400; 
    padding: 10px 0 30px 0; 
    font-size: 20px;
    opacity: 0;
    transform: translateY(20px);
}

.show {
    opacity: 1;
    transform: translateY(0px);
    transition: all 1s;
}

@media only screen and (max-width: 2000px)
{
    .main_container2 {
        height: 1000px;
    }
    
    .image_container {
        position: absolute;
        top: 130px;
        /* width: calc(100% - 200px); */
        background-image: url("../../../public/assets/main-hero--img.png");
        background-size: cover;
        height: 87%;
        display: flex;
    }

    .image_content {
        color: white;
        padding: 200px 0;
    }
}

@media only screen and (max-width: 1350px) {
    .main_container2 {
        height: 600px;
    }

    .image_container {
        position: absolute;
        top: 100px;
        /* width: calc(100% - 200px); */
        background-image: url("../../../public/assets/main-hero--img.png");
        background-size: cover;
        height: 83.5%;
        display: flex;
    }

    .image_content {
        color: white;
        padding: 100px 0;
    }

    .main_container_title {
        font-weight: 500;
        font-size: 30px;
        padding: 8px 0 14px 0
    }
    
    .main_container_content {
        font-weight: 400; 
        padding: 10px 0 30px 0; 
        font-size: 17px;
    }
}

@media only screen and (max-width: 1100px) {
    .image_container {
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .image_container {
        justify-content: center;
    }

    .image_container .col-2 {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .main_container2 {
        height: 650px;
    }

    .left {
        display: none;
    }
    .right {
        display: none;
    }
    .image_container {
        top: 20px;
    }
}
