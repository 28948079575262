/* Login.module.css */

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; 
  

}



.logoSection {

  width: 580px;
  
}

.enterprises_logo_wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.formSection {
  text-align: center;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  margin: 57px 0px;

}

.formSection h2 {
  margin: 0;
}

.inputGroup {
  margin: 10px 0;
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 420px;
  margin-right: 11px;

}

input[type="text"]
{
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.loginContainer .formSection .enterprise_form_wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;

}
.loginContainer .formSection .enterprise_form_wrapper .enterprise_form_input_group{
  display: flex;
  flex-direction: column;
}

.formSection .enterprise_form_wrapper .enterprise_login_btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  height: 113px;
  width: 109px;
}
.enterprise_login_btn:hover {
  background-color: #007bff;
  color: #000;
  padding: 10px 20px;
  border: 1px solid #007bff;
  border-radius: 3px;
  cursor: pointer;
  height: 106px;
  width: 109px;
}

.infoSection {
  /* Style for the service info section */
}
@media only screen and (max-width: 480px) and (min-width: 220px) {

  .loginContainer .formSection .enterprise_form_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
  
  }
  .loginContainer .formSection .enterprise_form_wrapper .enterprise_form_input_group{
    display: flex;
    flex-direction: column;
  }
  
  .formSection .enterprise_form_wrapper .enterprise_login_btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    height: 113px;
    width: 109px;
  }

  .logoSection {
    height: 174px;
    width: 340px;
    
  }
  .formSection {

    margin: 0px 0px;

  }
  .inputGroup {

    width: 221px;
    

  }
  .enterprises_logo_wrapper >img{
    width: 340px;
   }
   .inputGroup {
  
    width:  197px;

  
  }
  
} 
@media only screen and (max-width: 768px) and (min-width: 481px){

} 


@media only screen and (max-width: 1024px)and (min-width: 769px) {
 
}



