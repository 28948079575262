

.appstore__container {

    background-color: #eff1f6;
    padding-top: 3rem;
    display: flex;


}

.appstore__inner {
    margin: 0 auto;
    width: 80%;


}

.appstore__left .header h1 {
    margin-left: -20px;



}

.appstore__content {
    display: flex;
    align-items: flex-start;

}

.appstore__left {
    flex: 0.6;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.appstore__left .header {
    margin-left: 20px
}

.appstore__left >h1 {
    color: #343434
}

.desc>p {
    color: #5f5f60;
    font-size: 19px;
}

.tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;

}

.tag__box {
    margin-right: 10px;
    padding: 2px 10px;
    border-radius: 1rem;
    border: 1px solid #3e4a58;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

.buttons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 300px;

}

.btn {
    padding: 1rem 0.2rem;
    cursor: pointer;
}

.appstore__right {
    flex: 0.4;

}

.appstore__right img {
    width: 580px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

}

@media only screen and (min-width: 2302px) and (max-width: 3000px) {
    .appstore__left .header h1 {
        margin-left: -20px;



    }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appstore__container {
        background-color: #eff1f6;
        display: flex;

    }

    .appstore__left {

        margin-top: 0rem;
    }

    .appstore__left .header>h1 {
        color: #343434;
        font-size: 22px;
    }

    .desc>p {
        color: #5f5f60;
        font-size: 17px;
    }

    .btn {
        margin: 1rem 0.3rem;
        cursor: pointer;

    }

    .appstore__right {
        position: relative;
        bottom: 0;

        display: flex;
        flex-direction: flex-end;
        align-items: flex-end;

    }

    .appstore__right img {
        width: 390px;
        height: 390px;
        /* bottom:0 */

    }


    .appstore__right {
        /* background-color: yellow; */
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .appstore__container {

        background-color: #eff1f6;
        display: flex;

    }

    .appstore__left {

        margin-top: 0rem;
    }

    .appstore__left .header>h1 {
        color: #343434;
        font-size: 22px;
    }

    .desc>p {
        color: #5f5f60;
        font-size: 17px;
    }

    .btn {
        margin: 1rem 0.3rem;
        cursor: pointer;

    }

    .appstore__right img {
        width: 420px;
        height: 310px;

    }

    .appstore__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .appstore__right {
        /* background-color: yellow; */
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 480px) {
    .appstore__container {
        background-color: #eff1f6;
        display: flex;
        /* height: 690px; */
    }

    .appstore__left {

        margin-top: 0rem;
    }

    .appstore__left .header>h1 {
        color: #343434;
        font-size: 22px;
    }

    .desc>p {
        color: #5f5f60;
        font-size: 17px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 200px;

    }

    .btn {
        margin: 1rem 0.3rem;
        cursor: pointer;

    }

    .appstore__content {
        display: flex;

        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .appstore__right img {
        width: 370px;
        height: 300px;

    }


}

.home__container1 {
    background-color: #455964;
    height: 580px;
    display: flex;
    padding-top: 3rem;
    z-index: 9999;
}

.home__inner {
    margin: auto;
    width: 80%;

}

.home__content {
    display: flex;

    align-items: center;
    /* width: 300px; */

    /* flex:1 */


}

.home__content .home__left {
    flex: 0.6;

    flex-wrap: wrap;



}

h1 {
    color: #fff;
    font-weight: 500;
}

h2 {
    color: #fff;
    font-size: 19px;
    font-weight: 400;
}

.home__right1 {
    flex: 0.4;



}

.home__right1 img {
    width: 450px;
    height: 450px;


}

.home__container2 {
    background-color: #eff1f6;
    height: 580px;
    display: flex;
    padding-top: 3rem;

}

.home__content .home__left .black__text {
    color: #42545b
}

.home__content .home__left .black__text_desc {
    color: #42545b;
    font-size: 19px;
    margin-left: -18px;
}

.home__left .white__text_desc {
    margin-left: -18px;
}

.home__container2 .home__inner .home__content .home__right2 {
    background-color: #bcc2be;
    flex: 0.4
}

.home__left h1 {
    margin-left: -20px;

}

@media only screen and (max-width: 2300px) {
    .home__content .home__left .black__text_desc {
        color: #42545b;
        font-size: 19px;
        margin-left: -18px;
    }

    .home__left .white__text_desc {
        margin-left: -18px;
    }


}

@media only screen and (max-width: 1201px) {
    .home__right1 img {
        width: 450px;
        height: 450px;


    }


}

@media only screen and (max-width: 800px) {
    .home__right1 img {
        width: 450px;
        height: 450px;

    }



}

@media only screen and (max-width: 768px) {
    .home__right1 img {
        width: 450px;
        height: 450px;


    }

    .home__content {
        display: flex;

        flex-direction: column;

        justify-content: center;

    }

    .home__right1,
    .home__right2 {
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 480px) {
    .home__right1 img {
        width: 409px;
        height: 420px;

    }

    .home__content {
        display: flex;
        flex-direction: column;
        justify-self: center;
        justify-content: center;


    }
  
    .main_container > img{
        width: 30px;
    }

}

.about {
    /* padding-bottom: 10px; */
}






.footer {
    width: 100%;
    background: #2b2b2b;
    padding-top: 30px;
    padding-bottom: 30px;
    /* color: #b1b1b1; */
}

.footer a:link {
    color: #b1b1b1;
}

.footer a:visited {
    color: #b1b1b1;
}

.footer a:focus {
    color: #b1b1b1;
}

.footer a:hover {
    color: #b1b1b1;
}



.footer_company {
    text-align: left;
    font-size: 11px;
}

.footer_company_name {
    font-weight: bold;
}

.footer_company_detail {
    margin-top: 5px;
}
.footer_inner{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
   
}
.footer_inner{
    display: flex;
    align-items: center;
    width: 80%;
    margin:auto
   
}
.footer_hr {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 30%;
    border-top-style: solid;
    border-top-color: white;
}

.incDetail {
    color: #b1b1b1;
    text-align: left;


}

.incName {
    font-size: 24px;
    font-weight: bold;
    color: #b1b1b1;
    padding-bottom: 2rem;

}

.incHr {
    border-top: 1px dotted #f5f7f7;
}

.red_bold {
    color: #990000;
    font-size: 20px;
    font-weight: bold
}

.headerLogo img{
    
    width: 22vw;
    margin-left: 100px;
}
@media only screen and (max-width: 480px) {
   
  
    .headerLogo img{
        width: 60vw;
        margin-left: 30px;
        
    }

}